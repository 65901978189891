<div class="fixed-mobile">
  <div class="mobile-price-div">
    <p class="fixed-title">Выбранные модули</p>

    <p *ngIf="contactsPrice !== 130000"
       class="fixed-price">
      {{
      (
      basePrice +
      personalManagerCoast +
      smsSendCoast +
      contactsPrice +
      messagePrice +
      ensendCoast +
      enrecomCoast +
      trackmoduleCoast +
      whatsappCoast
      ).toString() | imask: maskNumber
      }}
      <span>₽/месяц</span>
    </p>
    <p *ngIf="contactsPrice === 130000"
       class="fixed-price">
      Индивидуально
    </p>
  </div>

  <div class="mobile-module-box">

    <div class="mobile-module mobile-module--yellow">
      <p *ngIf="contactsPrice === 25000"
         class="fixed_check--yellow">Тариф: до 100 000 контактов</p>
      <p *ngIf="contactsPrice === 37500"
         class="fixed_check--yellow">Тариф: до 150 000 контактов</p>
      <p *ngIf="contactsPrice === 50000"
         class="fixed_check--yellow">Тариф: до 200 000 контактов</p>
      <p *ngIf="contactsPrice === 62500"
         class="fixed_check--yellow">Тариф: до 250 000 контактов</p>
      <p *ngIf="contactsPrice === 75000"
         class="fixed_check--yellow">Тариф: до 300 000 контактов</p>
      <p *ngIf="contactsPrice === 87500"
         class="fixed_check--yellow">Тариф: до 350 000 контактов</p>
      <p *ngIf="contactsPrice === 100000"
         class="fixed_check--yellow">Тариф: до 400 000 контактов</p>
      <p *ngIf="contactsPrice === 112500"
         class="fixed_check--yellow">Тариф: до 450 000 контактов</p>
      <p *ngIf="contactsPrice === 125000"
         class="fixed_check--yellow">Тариф: до 500 000 контактов</p>
      <p *ngIf="contactsPrice === 130000"
         class="fixed_check--yellow">Тариф: > 500 000 контактов</p>
    </div>

    <div class="mobile-module"
         (click)="
        addedService.tracking = !addedService.tracking; addCoast()
      "
         *ngIf="addedService.tracking && !addedService.enrecomModule">
      <p>Отслеживание действий</p>
      <img class="cancel"
           src="/assets/img/price/cancel-white.svg"
           alt="" />
    </div>

    <div class="mobile-module"
         (click)="
        addedService.enrecomModule = !addedService.enrecomModule; addedService.tracking = false; addCoast()
      "
         *ngIf="addedService.enrecomModule">
      <p>enRecom</p>
      <img class="cancel"
           src="/assets/img/price/cancel-white.svg"
           alt="" />
    </div>

    <div class="mobile-module"
         (click)="
        addedService.similarProducts = !addedService.similarProducts; addCoast()
      "
         *ngIf="addedService.similarProducts">
      <p>enRecom (Похожие, Новинки)</p>
      <img class="cancel"
           src="/assets/img/price/cancel-white.svg"
           alt="" />
    </div>

    <div class="mobile-module"
         (click)="addedService.smsSend = !addedService.smsSend; addCoast()"
         *ngIf="addedService.smsSend">
      <p>Отправка SMS-сообщений</p>
      <img class="cancel"
           src="/assets/img/price/cancel-white.svg"
           alt="" />
    </div>

    <div class="mobile-module"
         (click)="
        addedService.whatsapp = !addedService.whatsapp; addCoast()
      "
         *ngIf="addedService.whatsapp">
      <p>WhatsApp (диалоги оплачиваются отдельно)</p>
      <img class="cancel"
           src="/assets/img/price/cancel-white.svg"
           alt="" />
    </div>
  </div>
</div>

<div class="main-grid-price">
  <div class="title-box">
    <p class="sub-title">Калькулятор</p>
    <h1 class="title">Выберите модули для своего бизнеса</h1>
  </div>

  <div class="fixed">
    <div class="fixed-block">
      <p class="fixed-title">Выбранные модули</p>

      <p *ngIf="contactsPrice === 25000"
         class="check-text fixed_check">Тариф: до 100 000 контактов</p>
      <p *ngIf="contactsPrice === 37500"
         class="check-text fixed_check">Тариф: до 150 000 контактов</p>
      <p *ngIf="contactsPrice === 50000"
         class="check-text fixed_check">Тариф: до 200 000 контактов</p>
      <p *ngIf="contactsPrice === 62500"
         class="check-text fixed_check">Тариф: до 250 000 контактов</p>
      <p *ngIf="contactsPrice === 75000"
         class="check-text fixed_check">Тариф: до 300 000 контактов</p>
      <p *ngIf="contactsPrice === 87500"
         class="check-text fixed_check">Тариф: до 350 000 контактов</p>
      <p *ngIf="contactsPrice === 100000"
         class="check-text fixed_check">Тариф: до 400 000 контактов</p>
      <p *ngIf="contactsPrice === 112500"
         class="check-text fixed_check">Тариф: до 450 000 контактов</p>
      <p *ngIf="contactsPrice === 125000"
         class="check-text fixed_check">Тариф: до 500 000 контактов</p>
      <p *ngIf="contactsPrice === 130000"
         class="check-text fixed_check">Тариф: > 500 000 контактов</p>
      <p class="check-text fixed_check"
         *ngIf="addedService.tracking && !addedService.enrecomModule">
        Отслеживание действий
        <img class="cancel"
             (click)="
            addedService.tracking = !addedService.tracking; addCoast()
          "
             src="/assets/img/price/cancel.svg"
             alt="" />
      </p>
      <p class="check-text fixed_check"
         *ngIf="addedService.enrecomModule">
        Модуль рекомендаций
        <img class="cancel"
             (click)="
            addedService.enrecomModule = !addedService.enrecomModule; addedService.tracking = false; addCoast()
          "
             src="/assets/img/price/cancel.svg"
             alt="" />
      </p>
      <p class="check-text fixed_check"
         *ngIf="addedService.similarProducts">
        enRecom (Похожие, Новинки)
        <img class="cancel"
             (click)="
            addedService.similarProducts = !addedService.similarProducts; addCoast()
          "
             src="/assets/img/price/cancel.svg"
             alt="" />
      </p>
      <p class="check-text fixed_check"
         *ngIf="addedService.smsSend">
        Отправка SMS-сообщений
        <img class="cancel"
             (click)="
            addedService.smsSend = !addedService.smsSend; addCoast()
          "
             src="/assets/img/price/cancel.svg"
             alt="" />
      </p>

      <p class="check-text whatsapp-icon"
         *ngIf="addedService.whatsapp">
        WhatsApp <span class="whatsapp">(диалоги оплачиваются отдельно)</span>
        <img class="cancel"
             (click)="
            addedService.whatsapp = !addedService.whatsapp; addCoast()
          "
             src="/assets/img/price/cancel.svg"
             alt="" />
      </p>

      <p *ngIf="contactsPrice !== 130000"
         class="fixed-price">
        {{
        (
        basePrice +
        contactsPrice +
        messagePrice +
        personalManagerCoast +
        smsSendCoast +
        ensendCoast +
        enrecomCoast +
        trackmoduleCoast +
        whatsappCoast
        ).toString() | imask: maskNumber
        }}
        <span>₽/месяц</span>
      </p>
      <p *ngIf="contactsPrice === 130000"
         class="fixed-price">
        Индивидуально
      </p>
      <button class="fixed-button"
              (click)="goForm()">Написать нам</button>
    </div>
  </div>

  <p class="module-title table-title">Тарифный план</p>

  <div class="base-module">
    <div class="service-block__content">
      <div class="service-block__title-box">
        <p class="service-block__title">Тарифный план</p>
      </div>
      <div class="long-title-box">
        <p class="long-title">Количество контактов</p>
      </div>

      <div class="slider-box">
        <div class="slider-numbers-box">

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 25000 }"
               class="slider-numbers-value">
              до&nbsp;100&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 37500 }"
               class="slider-numbers-value">
              до&nbsp;150&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 50000 }"
               class="slider-numbers-value">
              до&nbsp;200&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 62500 }"
               class="slider-numbers-value">
              до&nbsp;250&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 75000 }"
               class="slider-numbers-value">
              до&nbsp;300&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 87500 }"
               class="slider-numbers-value">
              до&nbsp;350&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 100000 }"
               class="slider-numbers-value">
              до&nbsp;400&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 112500 }"
               class="slider-numbers-value">
              до&nbsp;450&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 125000 }"
               class="slider-numbers-value">
              до&nbsp;500&nbsp;000
            </p>
          </div>

          <div class="slider-numbers-div">
            <p [ngClass]="{ 'slider-numbers-value--active': contactsPrice === 130000 }"
               class="slider-numbers-value">
              >&nbsp;500&nbsp;000
            </p>
          </div>
        </div>
      </div>
      <tui-slider [(ngModel)]="contactsPrice"
                  [keySteps]="keyStepContacts"
                  [min]="25000"
                  [max]="130000"
                  [segments]="10"
                  [steps]="10"></tui-slider>

      <div class="block__text-content">
        <div class="block__text-list">
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Отправка емейл-сообщений</p>
          </div>
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Подключение до 5 доменов</p>
          </div>
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Модуль поп-апов</p>
          </div>
        </div>
        <div class="block__text-list">
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Первоначальная настройка аккаунта</p>
          </div>
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Неограниченные доступы к аккаунту</p>
          </div>
          <div class="icon-text">
            <img src="/assets/img/price/check.svg"
                 alt="">
            <p>Неограниченные отправки PUSH сообщений</p>
          </div>
        </div>
      </div>
    </div>
    <div class="black-block">
      <div *ngIf="contactsPrice != 130000"
           class="black-block__group">
        <p class="black-block__price base">
          {{ contactsPrice.toString() | imask: maskNumber }}
        </p>
        <p class="black-block__text">&nbsp;₽/месяц</p>
      </div>
      <div *ngIf="contactsPrice === 130000"
           class="black-block__group">
        <p class="black-block__price base">
          Цена рассчитывается индивидуально
        </p>
      </div>
    </div>
  </div>

  <p class="module-title">Дополнительные опции</p>

  <div class="service-block service-block--left"
       [ngClass]="
      addedService.tracking
        ? 'service-block--active'
        : 'service-block--inactive'
    ">
    <div class="service-block__content">
      <svg (click)="
          addTracking(); addCoast()
        "
           *ngIf="!addedService.tracking"
           class="add-button"
           width="52"
           height="52"
           viewBox="0 0 52 52"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="fill-yellow"
                cx="26"
                cy="26"
                r="26"
                fill="#ffffff" />
        <circle cx="26"
                cy="25.9999"
                r="23.6364"
                fill="#2E57FA" />
        <path class="fill-yellow"
              d="M16.5455 25.8718L35.2125 25.8718"
              stroke="#ffffff"
              stroke-width="1.69392" />
        <path class="fill-yellow"
              d="M25.8794 16.8284L25.8794 35.2125"
              stroke="#ffffff"
              stroke-width="1.69392" />
      </svg>

      <img class="add-button add-button--checked"
           src="/assets/img/price/checked.svg"
           alt=""
           (click)="addTracking(); addCoast()"
           [ngClass]="{ 'add-button--checked-true': addedService.tracking }" />
      <div class="long-title-box">
        <p class="long-title">Отслеживание действий</p>
      </div>
      <p class="module-text text-box">
        С помощью скрипта или API соберите данные о поведении пользователей на сайте, из CRM или других систем и
        передайте их в enKod, чтобы:
      </p>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Сегментировать пользователей по их действиям на сайте и покупательской активности</p>
      </div>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Подсчитать выручку с рассылки</p>
      </div>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Запустить триггерные механики (брошенную корзину или просмотр, приветственную цепочку, реактивацию)</p>
      </div>
      <p class="description__text no-recom tracking-desc">
        Трекинг для сайта и мобильного приложения оплачивается по отдельности.
      </p>
    </div>

    <div class="black-block">
      <div class="black-block__group">
        <p class="black-block__price">
          10 000
        </p>
        <p class="black-block__text">&nbsp;₽/месяц</p>
      </div>
    </div>
  </div>

  <div class="service-block service-block--right"
       [ngClass]="{
        'service-block--recom': addedService.similarProducts,
        'service-block--active': addedService.similarProducts || addedService.enrecomModule,
        'service-block--inactive': !addedService.enrecomModule && !addedService.similarProducts
       }
  ">
    <div class="service-block__content">
      <svg (click)="
          addedService.enrecomModule = !addedService.enrecomModule; addedService.similarProducts = false; addTracking(); addCoast()
        "
           (click)="cancelTrack()"
           *ngIf="!addedService.enrecomModule && !addedService.similarProducts"
           class="add-button"
           width="52"
           height="52"
           viewBox="0 0 52 52"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="fill-yellow"
                cx="26"
                cy="26"
                r="26"
                fill="#ffffff" />
        <circle cx="26"
                cy="25.9999"
                r="23.6364"
                fill="#2E57FA" />
        <path class="fill-yellow"
              d="M16.5455 25.8718L35.2125 25.8718"
              stroke="#ffffff"
              stroke-width="1.69392" />
        <path class="fill-yellow"
              d="M25.8794 16.8284L25.8794 35.2125"
              stroke="#ffffff"
              stroke-width="1.69392" />
      </svg>

      <img class="add-button add-button--checked"
           src="/assets/img/price/checked.svg"
           alt=""
           (click)="
          addedService.enrecomModule = !addedService.enrecomModule; addCoast(); addTracking()
        "
           [ngClass]="{ 'add-button--checked-true': addedService.enrecomModule || addedService.similarProducts}" />

      <div class="long-title-box">
        <p class="long-title">Модуль рекомендаций</p>
      </div>
      <p class="module-text text-box">
        Инструмент для формирования и показа рекомендаций. Мы помогаем вам увеличивать прибыль, вы помогаете своим
        клиентам сделать правильный выбор.
      </p>
      <div class="check-text--enrecom">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Отслеживание действий <span>(включено)</span></p>
        </div>
      </div>
      <div class="check-text--enrecom">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Сопутствующие товары</p>
        </div>

        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Подберем товары, дополняющие друг друга.
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Подберем товары, дополняющие друг друга.
          </p>
        </div>
      </div>

      <div class="check-text--enrecom">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Популярные товары</p>
        </div>

        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Рекомендуем пользователям наиболее популярные товары.
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Рекомендуем пользователям наиболее популярные товары.
          </p>
        </div>
      </div>

      <div class="check-text--enrecom">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Персональные</p>
        </div>

        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Подберем подходящие товары для пользователя, основываясь на его поведении.
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Подберем подходящие товары для пользователя, основываясь на его поведении.
          </p>
        </div>
      </div>

      <!-- Похожие товары -->
      <div class="check-text--enrecom similarProducts">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Похожие товары</p>
        </div>
        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Покажем товары, которые максимально похожи по внешнему виду или по описанию.
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Покажем товары, которые максимально похожи по внешнему виду или по описанию.
          </p>
        </div>
      </div>

      <!-- Новинки -->
      <div class="check-text--enrecom similarProducts">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Новинки</p>
        </div>
        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Товары, которые недавно появились в каталоге.
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Товары, которые недавно появились в каталоге.
          </p>
        </div>
      </div>

      <!-- Бестселлеры -->
      <div class="check-text--enrecom similarProducts">
        <div class="icon-text">
          <img src="/assets/img/price/check.svg"
               alt="">
          <p>Бестселлеры</p>
        </div>
        <div class="tooltip-box">
          <svg class="tooltip"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path class="tooltip--yellow"
                  d="M12 0C5.36705 0 0 5.36752 0 12C0 18.633 5.36752 24 12 24C18.633 24 24 18.6325 24 12C24 5.36705 18.6324 0 12 0ZM12 22.125C6.40345 22.125 1.875 17.5962 1.875 12C1.875 6.40345 6.40383 1.875 12 1.875C17.5965 1.875 22.125 6.40383 22.125 12C22.125 17.5965 17.5961 22.125 12 22.125Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 10.0466C11.4822 10.0466 11.0625 10.4663 11.0625 10.9841V17.0213C11.0625 17.5391 11.4822 17.9588 12 17.9588C12.5178 17.9588 12.9375 17.539 12.9375 17.0213V10.9841C12.9375 10.4663 12.5178 10.0466 12 10.0466Z"
                  fill="#5C5C5C" />
            <path class="tooltip--yellow"
                  d="M12 8.89868C12.699 8.89868 13.2656 8.33204 13.2656 7.63306C13.2656 6.93407 12.699 6.36743 12 6.36743C11.301 6.36743 10.7344 6.93407 10.7344 7.63306C10.7344 8.33204 11.301 8.89868 12 8.89868Z"
                  fill="#5C5C5C" />
          </svg>

          <img class="bup-mob"
               src="/assets/img/price/bup-mobile.svg"
               alt="" />

          <div class="hint">
            <p class="hint__text">
              Рекомендуем пользователям самые продаваемые товары
            </p>
          </div>
        </div>

        <div class="hint-mob">
          <p class="hint__text">
            Рекомендуем пользователям самые продаваемые товары
          </p>
        </div>
      </div>

      <div class="description__group">
        <p class="description__text">Алгоритм «Похожие товары» и «Новинки» можно подключить отдельно за 18 750 ₽/месяц.
        </p>
        <p class="description__link"
           (click)="addedService.similarProducts = !addedService.similarProducts; addedService.enrecomModule = false; addCoast()">
          Подключить «Похожие товары» и «Новинки»</p>
      </div>
    </div>

    <div class="black-block">
      <div class="black-block__group">
        <p *ngIf="!addedService.similarProducts"
           class="black-block__price">
          38 750
        </p>
        <p *ngIf="addedService.similarProducts"
           class="black-block__price">
          18 750
        </p>
        <p class="black-block__text">&nbsp;₽/месяц</p>
      </div>
    </div>
  </div>

  <div class="service-block service-block--left2"
       [ngClass]="
 addedService.smsSend
   ? 'service-block--active'
   : 'service-block--inactive'
">
    <div class="service-block__content">
      <svg (click)="
     addedService.smsSend = !addedService.smsSend; addCoast()
   "
           *ngIf="!addedService.smsSend"
           class="add-button"
           width="52"
           height="52"
           viewBox="0 0 52 52"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="fill-yellow"
                cx="26"
                cy="26"
                r="26"
                fill="#ffffff" />
        <circle cx="26"
                cy="25.9999"
                r="23.6364"
                fill="#2E57FA" />
        <path class="fill-yellow"
              d="M16.5455 25.8718L35.2125 25.8718"
              stroke="#ffffff"
              stroke-width="1.69392" />
        <path class="fill-yellow"
              d="M25.8794 16.8284L25.8794 35.2125"
              stroke="#ffffff"
              stroke-width="1.69392" />
      </svg>

      <img class="add-button add-button--checked"
           src="/assets/img/price/checked.svg"
           alt=""
           (click)="addedService.smsSend = !addedService.smsSend; addCoast()"
           [ngClass]="{ 'add-button--checked-true': addedService.smsSend }" />
      <div class="long-title-box">
        <p class="long-title">Отправка <br> SMS-сообщений</p>
      </div>
      <p class="module-text text-box">
        Опция позволяет отправлять SMS-сообщения и смотреть по ним статистику в интерфейсе enKod. Отправка SMS
        оплачивается отдельно, по тарифам вашего провайдера.
      </p>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Отправка мгновенно или по расписанию</p>
      </div>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Сокращатель ссылок</p>
      </div>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Подробные отчеты</p>
      </div>
      <p class="description__text no-recom sms">
        У нас есть интеграции с провайдерами: SMS Traffic, SMS Центр и Devino Telecom. Если вы используете другой
        сервис, то мы рассмотрим интеграцию с ним.
      </p>
    </div>

    <div class="black-block">
      <div class="black-block__group">
        <p class="black-block__price">
          6 250
        </p>
        <p class="black-block__text">&nbsp;₽/месяц</p>
      </div>
    </div>
  </div>


  <!-- Whatsapp -->
  <div class="service-block service-block--right2"
       [ngClass]="
            addedService.whatsapp
              ? 'service-block--active'
              : 'service-block--inactive'
  ">
    <div class="service-block__content">
      <svg (click)="
          addedService.whatsapp = !addedService.whatsapp; addCoast()
        "
           *ngIf="!addedService.whatsapp"
           class="add-button"
           width="52"
           height="52"
           viewBox="0 0 52 52"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle class="fill-yellow"
                cx="26"
                cy="26"
                r="26"
                fill="#ffffff" />
        <circle cx="26"
                cy="25.9999"
                r="23.6364"
                fill="#2E57FA" />
        <path class="fill-yellow"
              d="M16.5455 25.8718L35.2125 25.8718"
              stroke="#ffffff"
              stroke-width="1.69392" />
        <path class="fill-yellow"
              d="M25.8794 16.8284L25.8794 35.2125"
              stroke="#ffffff"
              stroke-width="1.69392" />
      </svg>

      <img class="add-button add-button--checked"
           src="/assets/img/price/checked.svg"
           alt=""
           (click)="addedService.whatsapp = !addedService.whatsapp; addCoast()"
           [ngClass]="{ 'add-button--checked-true': addedService.whatsapp}" />

      <div class="long-title-box">
        <p class="long-title">WhatsApp</p>
      </div>
      <p class="module-text text-box">
        Общайтесь с клиентами напрямую. Отправляйте уведомления о заказах и напоминания о мероприятиях.
      </p>
      <div class="icon-text">
        <img src="/assets/img/price/check.svg"
             alt="">
        <p>Ежемесячно 1 000 бесплатных диалогов категории Service на один WABA клиента</p>
      </div>
      <div class="icon-text last">
        <img src="/assets/img/price/warm.svg"
             alt="">
        <p>Отдельно оплачиваются диалоги в зависимости от категории*:</p>
      </div>
      <div class="whatsapp-group">
        <div class="whatsapp-text">
          <span>Service</span>
          <span class="whatsapp-description">свыше 1 000 бесплатных</span>
        </div>
        <p class="whatsapp-price">4,8 ₽</p>
      </div>
      <div class="whatsapp-group">
        <div class="whatsapp-text">
          <span>Authentication</span>
        </div>
        <p class="whatsapp-price">5,3 ₽</p>
      </div>
      <div class="whatsapp-group">
        <div class="whatsapp-text">
          <span>Utility</span>
        </div>
        <p class="whatsapp-price">5,8 ₽</p>
      </div>
      <div class="whatsapp-group">
        <div class="whatsapp-text">
          <span>Marketing</span>
        </div>
        <p class="whatsapp-price">9,8 ₽</p>
      </div>
      <p class="description__text whatsapp">*стоимость диалога зависит от курса доллара. Актуальную стоимость
        уточняйте у менеджера.
      </p>
    </div>


    <div class="black-block">
      <div class="black-block__group">
        <p class="black-block__price">
          5 000
        </p>
        <p class="black-block__text">&nbsp;₽/месяц</p>
      </div>
      <span class="black-block__descr">за 1 номер WABA</span>
    </div>
  </div>

  <div class="contacts">
    <h4 class="contacts__title">Мы всегда на связи</h4>
    <p class="contacts__text">Хотите стать нашим клиентом или попросить нас выступить на конференции? <br>
      Нет проблем, мы рассмотрим любой вопрос или предложение.
    </p>
    <div class="contacts__buttons">
      <!-- <button class="button__demo">
        Демо платформы
      </button> -->
      <button class="button__contact-us"
              (click)="goForm()">
        <img src="/assets/img/price/contact.png">
        <p>Связаться с нами</p>
      </button>
    </div>
  </div>
</div>