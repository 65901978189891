<footer id="form"
        [ngClass]="{'not-found': (abs$ | async) === '404', 'white-footer': (abs$ | async) === 'politics'} "
        data-aos="fade-up"
        data-aos-once="true">
  <section class="constacts">
    <div class="constacts__content">
      <div class="constacts__contact-us">
        <h4 class="constacts__title">Как с нами связаться</h4>
        <p>Расскажите нам о вашей задаче или задайте вопрос через форму обратной связи.
          Можете написать нам в любой удобный канал: телеграм, фейсбук, почта.</p>
        <div class="contacts__expets">
          <img src="/assets/img/footer/experts.png">
          <p>наши эксперты ответят в течение 20 минут.</p>
        </div>
      </div>
      <div class="constacts__address">
        <h4 class="constacts__title">experts@enkod.io</h4>
        <p>8-800-350-84-74 <br>
          Рязань, ул. Кальная 5, 390027 <br>
          Москва, ул. Бутлерова, д. 17Б, офис 35, 117342</p>
      </div>
    </div>
    <form [formGroup]="formGroup" (submit)="submit()" class="contacts__form">
      <input type="text"
             placeholder="Емейл"
             formControlName="email"
             pattern="([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)">
      <textarea cols="30"
                placeholder="Ваш вопрос"
                rows="10"
                formControlName="question"></textarea>
      <button [ngClass]="{ 'valid': formValid }" type="submit">Отправить</button>
    </form>
  </section>
  <section class="menu-row">
    <div class="menu-row__work">
      <div class="work__icon-text">
        <p class="work__title">Мы ищем</p>
        <img src="/assets/img/footer/search.svg">
      </div>
      <p class="work__text">Хочешь в IT и любишь драйв? <br>
        Тогда у нас есть классная <a href="http://jobs.enkod.io/">работа для тебя!</a>
      </p>
      <div class="work__links">
        <a href="https://enkod.io/personal-data/">Персональные данные</a>
        <a href="https://enkod.io/safety/">Защита</a>
        <a href="https://enkod.io/cookie/">Сookie</a>
        <a href="https://enkod.io/antispam-policy/">Антиспам политика</a>
      </div>
    </div>
    <div class="menu-row__table">
      <div class="table__group">
        <p>Каналы</p>
        <a href="https://enkod.io/email-newsletter/">E-mail</a>
        <a href="https://enkod.io/sms-sending/">SMS</a>
        <a href="https://enkod.io/push-mailing/">Push</a>
        <a href="https://enkod.io/product-recommendations/">Товарные рекомендации</a>
      </div>
      <div class="table__group">
        <p>Компания</p>
        <a href="https://enkod.io/contacts/">О нас</a>
        <a href="https://enkod.io/clients/">Наши клиенты</a>
        <a href="https://enkod.io/reviews/">Отзывы</a>
      </div>
      <div class="table__group">
        <p>Ресурсы</p>
        <a href="https://enkod.io/blog/">Блог</a>
        <a href="https://enkod.io/tag/keysy/">Кейсы</a>
        <a href="https://manual.enkod.io/doku.php">Мануал</a>
        <div class="table__item">
          <a>Интеграции</a>
          <p>В работе</p>
        </div>
      </div>
    </div>
  </section>
</footer>
<section class="bottom">
  <div class="bottom__bar">
    <p>© 2019 - 2023 enKod.</p>
    <div class="bottom__icons">
      <a href="https://t.me/+NLHXvc36DAwwMDc6">
        <svg width="32"
             height="32"
             viewBox="0 0 32 32"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32 16C32 24.8365 24.8365 32 16 32C7.16347 32 0 24.8365 0 16C0 7.16347 7.16347 0 16 0C24.8365 0 32 7.16347 32 16ZM10.7143 14.5343C9.11153 15.2341 7.46473 15.9532 5.95883 16.7827C5.17251 17.3584 6.21759 17.7657 7.19807 18.1477C7.35393 18.2084 7.50813 18.2685 7.65313 18.3285C7.7738 18.3656 7.89647 18.4047 8.0208 18.4443C9.11127 18.7917 10.3271 19.1791 11.3858 18.5963C13.1249 17.5973 14.766 16.4424 16.4059 15.2883C16.9432 14.9102 17.4803 14.5322 18.0207 14.1598C18.0461 14.1436 18.0746 14.1251 18.1059 14.1049C18.5662 13.8064 19.6016 13.1353 19.2186 14.0601C18.313 15.0505 17.3429 15.9271 16.3677 16.8087C15.7103 17.4027 15.0506 17.999 14.4066 18.6337C13.8457 19.0894 13.2633 20.0059 13.8913 20.6441C15.3379 21.6567 16.8071 22.6449 18.2755 23.6325C18.7533 23.9538 19.2311 24.2751 19.7079 24.5973C20.516 25.2425 21.779 24.7205 21.9567 23.7123C22.0357 23.2485 22.115 22.7848 22.1943 22.321C22.6327 19.7579 23.0713 17.1938 23.4587 14.6223C23.5113 14.2189 23.571 13.8156 23.6307 13.4121C23.7755 12.434 23.9204 11.4547 23.9657 10.4714C23.849 9.49007 22.6591 9.70587 21.997 9.92653C18.5935 11.2216 15.2241 12.6127 11.8679 14.0283C11.4877 14.1967 11.1023 14.3649 10.7143 14.5343Z"
                fill="currentColor" />
        </svg>
      </a>
      <a href="https://zen.yandex.ru/id/5f8ef360e73867664e9f5170">
        <svg width="32"
             height="32"
             viewBox="0 0 32 32"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="16"
                  cy="16"
                  r="16"
                  fill="currentColor" />
          <path d="M30 16.15V15.85C23.8 15.65 20.97 15.5 18.7 13.3C16.5 11.03 16.34 8.2 16.15 2H15.85C15.65 8.2 15.5 11.03 13.3 13.3C11.03 15.5 8.2 15.66 2 15.85V16.15C8.2 16.35 11.03 16.5 13.3 18.7C15.5 20.97 15.66 23.8 15.85 30H16.15C16.35 23.8 16.5 20.97 18.7 18.7C20.97 16.5 23.8 16.34 30 16.15Z"
                fill="#F2F3F7" />
        </svg>
      </a>
    </div>
  </div>
</section>


