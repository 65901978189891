<div class="main-grid">
  <div class="title-box">
      <h1 class="title">как начать</h1>
  </div>

  <p class="what-is-title opasity-ani">Как с нами работать?</p>

  <div class="what-box opasity-ani">
      <p class="what-box__title">Рассказать, что вам нужно</p>

      <p class="what-box__text">
        Покажите референсы, озвучьте цели и&nbsp;желания&nbsp;&mdash; все это поможет нам понять, какие сервисы использовать и&nbsp;с&nbsp;чего начинать автоматизацию вашего маркетинга.
      </p>

      <p class="what-box__title what-box__title--2">Подписать договор</p>

      <p class="what-box__text">
        Чтобы всем было спокойно, зафиксируем все&nbsp;то, о&nbsp;чем мы&nbsp;договорились, на&nbsp;бумаге.
      </p>

      <p class="what-box__title what-box__title--3">Прописать DNS-настройки / SDK методы</p>

      <p class="what-box__text">
        Для того чтобы ваши письма не&nbsp;попадали в&nbsp;спам, вам нужно прописать DNS настройки, которые мы&nbsp;для вас сформируем. А&nbsp;чтобы собирать информацию о&nbsp;действиях пользователей на&nbsp;вашем сайте, вам понадобится установить наш скрипт-трекер и&nbsp;прописать SDK методы. Все это совсем не&nbsp;сложно, особенно когда есть понятная инструкция 🙂
      </p>

      <p class="what-box__title what-box__title--4">Настроить инструменты</p>

      <p class="what-box__text what-box__text--mb">
        После бесплатного обучения по&nbsp;работе в&nbsp;платформе вам останется настроить инструменты под свои задачи. Выберите шаблон всплывающего окна или создайте свой, загрузите базу контактов и&nbsp;письма в&nbsp;платформу, задайте условия и&nbsp;канал для отправки рассылок. А&nbsp;мы&nbsp;расскажем вам, как это лучше всего сделать.
      </p>
  </div>
</div>
