import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CustomValidators } from '../../validators/custom-validators';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	MinLengthValidator,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteObserverService } from './../../services/route-observer.service';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
	goForm() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	constructor(
		private http: HttpClient,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private router: Router,
		private routeObserver: RouteObserverService
	) {
		this.loading$ = new BehaviorSubject(false);
	}
	formValid = false;

	loading$: BehaviorSubject<boolean>;

	observeRouteService: any;

	abs$;
	formDemo: FormGroup;
	formAbout: FormGroup;
	formCapabilities: FormGroup;
	formGetstarted: FormGroup;
	formContacts: FormGroup;

	formGroup: FormGroup;

	formSubmited = false;

	// ngOnChanges() {
	//   (this.formAbout || this.formCapabilities || this.formContacts || this.formDemo || this.formGetstarted).reset
	// }

	ngOnInit() {
		this.abs$ = this.routeObserver.routeNow;
		this.abs$.subscribe(value => {
			switch (value) {
				case 'about':
					this.formAbout.reset();
					this.formSubmited = false;

					break;

				case 'demo':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'elle.nikiforova':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'kristina.markina':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'alexander.chernov':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'maxim.tishininov':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'artur.kharitonov':
					this.formDemo.reset();
					this.formSubmited = false;
					break;

				case 'mission':
					this.formCapabilities.reset();
					this.formSubmited = false;
					break;

				case 'partners':
					this.formCapabilities.reset();
					this.formSubmited = false;
					break;

				case 'capabilities':
					this.formCapabilities.reset();
					this.formSubmited = false;
					break;

				case 'get-started':
					this.formGetstarted.reset();
					this.formSubmited = false;
					break;

				case 'contacts':
					this.formContacts.reset();
					this.formSubmited = false;
					break;

				default:
					break;
			}
		});

		//Форма единственная форма для отправки
		this.formGroup = this.fb.group({
			email: ['', [Validators.required]],
			question: ['', [Validators.required]]
		});

		this.formGroup.statusChanges.subscribe(status => {
			status === 'VALID'
				? (this.formValid = true)
				: (this.formValid = false);
		});
	}

	async transition(data) {
		this.loading$.next(true);
		await this.http
			.post('https://enkodlandingapi.enkod.tech', data)
			.pipe(
				catchError(err => {
					this.loading$.next(false);
					return of(err);
				})
			)
			.toPromise();
		this.loading$.next(false);
		this.formSubmited = true;
	}

	submit() {
		if (this.formValid) {
			const formData = this.formGroup.value;
			const sendData = {
				email: 'experts@enkod.io',
				messageId: 141,
				snippets: {
					email: formData.email,
					page: 'price',
					question: formData.question
				},
				type: 'transition'
			};
			this.http
				.post('https://enkodlandingapi.enkod.tech', sendData, { responseType: 'text' })
				.subscribe(response => {
					if (response === 'success') {
						this.formGroup.reset();
            this.formSubmited = true;
					}
				});
		}
	}
}
