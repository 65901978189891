<div class="main-grid">
  <h1 class="title-polit">ЗАЩИТА</h1>

  <h2 class="polit-title">Персональные данные под&nbsp;защитой</h2>

  <p class="polit-text">Мы&nbsp;никогда не&nbsp;будем продавать или предоставлять другим организациям персональные данные наших пользователей. Исключение составляет лишь требование судебных инстанций. Информация, представленная enSend, доступна только ограниченному списку сотрудников, ответственных за&nbsp;управление этими данными.</p>

  <h2 class="polit-title">Мы — против Спама!</h2>

  <p class="polit-text">Мы&nbsp;категорически против покупки баз электронных адресов и&nbsp;отправки ложной, вводящей в&nbsp;заблуждение информации в&nbsp;заголовки, темы сообщений или их&nbsp;содержание. Мы&nbsp;также гарантируем, что сами не&nbsp;покупаем и&nbsp;не&nbsp;продаем подписчиков. <br><br>

  Мы&nbsp;рекомендуем использовать белые механизмы сбора данных, а&nbsp;именно двойное подтверждение подписки&nbsp;&mdash; Double opt-in. В&nbsp;системе enKod для этого предусмотрены специальные Группы Рассылок, которые не&nbsp;позволяют отправлять сообщения посредством любого канала без подтверждения пользователя.</p>
</div>
