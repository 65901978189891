<div class="main-grid">
  <h1 class="title-polit">ПОЛИТИКА ИСПОЛЬЗОВАНИЯ ФАЙЛОВ COOKIE</h1>

  <h2 class="polit-title">Что такое cookie?</h2>

  <p class="polit-text">Cookie&nbsp;&mdash; это небольшой текстовый файл, хранящий информацию о&nbsp;настройках интернет-браузеров. Эта технология используется практически на&nbsp;всех современных сайтах. Файлы cookie скачиваются браузером при первом посещении сайта. При повторном заходе на&nbsp;этот сайт с&nbsp;того&nbsp;же устройства файл cookie вместе с&nbsp;хранящейся в&nbsp;нем информацией либо отправляется обратно на&nbsp;исходный сайт (основной файл cookie), либо на&nbsp;сторонний сайт, которому он&nbsp;принадлежит (сторонний файл cookie). Таким образом, мы&nbsp;получаем информацию о&nbsp;повторном открытии сайта через тот&nbsp;же браузер, и&nbsp;в&nbsp;некоторых случаях может изменяться отображаемый контент. Файлы cookie могут быть полезны и&nbsp;пользователю, при повторном заходе делая интерфейс сайта более удобным и&nbsp;персонализированным. Если Вы&nbsp;используете то&nbsp;же устройство и&nbsp;тот&nbsp;же браузер, cookie запомнят Ваши предпочтения и&nbsp;адаптируют отображаемый контент в&nbsp;соответствии с&nbsp;Вашими интересами и&nbsp;потребностями.</p>

  <h2 class="polit-title">Использование файлов cookie на&nbsp;этом сайте, не&nbsp;требующее согласия пользователя.</h2>

  <p class="polit-text">Есть строго необходимые файлы cookie, так называемые технические файлы cookie, которые никак не&nbsp;влияют на&nbsp;доступность функционала сайта для пользователя. Они сохраняются на&nbsp;Вашем устройстве только в&nbsp;случае, если&nbsp;Вы действительно посещаете наш сайт. Также такие cookie упрощают переход с&nbsp;протокола HTTP на&nbsp;HTTPS при смене страниц, тем самым обеспечивая сохранность передаваемых данных. Кроме того, они используются для хранения согласия пользователей в&nbsp;отношении использования файлов cookie нашим сайтом. Для использования технических файлов cookie согласие пользователя не&nbsp;требуется. С помощью изменения настроек на&nbsp;данном сайте&nbsp;Вы не&nbsp;сможете отключить установку технических файлов cookie.</p>

  <h2 class="polit-title">Категории файлов cookie</h2>

  <p class="polit-text">Выделяются четыре категории файлов cookie в&nbsp;соответствии с&nbsp;их&nbsp;функциями и&nbsp;целями: технические, файлы cookie производительности, функциональные и&nbsp;рекламные.</p>

  <h2 class="polit-title">Технические файлы cookie</h2>

  <p class="polit-text">Такие файлы важны для обеспечения навигации и&nbsp;использования функционала сайта. Без таких файлов cookie при возврате на&nbsp;страницу в&nbsp;рамках одного сеанса некоторые функции могут оказаться недоступными, например не&nbsp;сохранится история Ваших действий.</p>

  <h2 class="polit-title">Файлы cookie производительности</h2>

  <p class="polit-text">Такие файлы собирают информацию об&nbsp;использовании сайта&nbsp;&mdash; например, данные о&nbsp;частоте посещаемости страниц, подтверждения о&nbsp;получении пользователем уведомлений об&nbsp;ошибках и&nbsp;так далее. Они не&nbsp;сохраняют информацию, позволяющую идентифицировать конкретного пользователя. Собранная информация используется для целей статистики и&nbsp;носит анонимный характер. Эти файлы используются исключительно для улучшения работы сайта и&nbsp;повышения удобства его использования.</p>

  <h2 class="polit-title">Рекламные файлы cookie</h2>

  <p class="polit-text">Такие файлы используются для отображения рекламных объявлений и&nbsp;других сообщений с&nbsp;учетом Ваших интересов и&nbsp;предпочтений. Они также используются для того, чтобы ограничивать число показов одного и&nbsp;того&nbsp;же рекламного объявления одному пользователю, и&nbsp;для измерения эффективности рекламных кампаний. Эти файлы запоминают переходы пользователя на&nbsp;сайт рекламодателя&nbsp;/ страничку с&nbsp;рекламным объявлением и&nbsp;могут передавать эту информацию сторонним организациям, например рекламным агентствам. Файлы cookie, используемые для таргетирования контента и&nbsp;рекламных объявлений, часто связаны с&nbsp;функционалом сайта, предоставляемым сторонними организациями.</p>

  <h2 class="polit-title">Настройки файлов cookie на&nbsp;этом сайте</h2>

  <p class="polit-text">В&nbsp;любое время&nbsp;Вы можете полностью запретить использование файлов cookie, изменив настройки браузера. Если Вы&nbsp;измените настройки и&nbsp;заблокируете определенные файлы cookie, использование некоторого функционала нашего сайта станет невозможным, и&nbsp;мы&nbsp;не&nbsp;сможем предоставить ранее заданные вами персональные настройки и&nbsp;параметры.</p>

  <h2 class="polit-title">Файлы cookie на&nbsp;нашем сайте</h2>

  <p class="polit-text">Мы&nbsp;используем файлы cookie для того, чтобы обеспечить эффективную работу сайта и&nbsp;улучшить взаимодействие с&nbsp;нашими пользователями. Если Вы&nbsp;не&nbsp;выполните изменение настроек, мы&nbsp;будем считать, что&nbsp;Вы даете согласие на&nbsp;использование сайтом всех типов файлов cookie. При желании&nbsp;Вы можете в&nbsp;любое время изменить настройки использования файлов cookie.</p>
</div>
