<div class="main-grid">
    <div class="title-box">
        <h1 class="title">КЛИЕНТЫ</h1>
    </div>

    <p class="left-text opasity-ani">Отзывы</p>

    <div class="slider-box opasity-ani">
        <carousel style="height: 100%;"
            [cellWidth]="'100%'"
        >
            
            <div class="carousel-cell">
                <img class="carousel-logo" src="/assets/img/partners/mks-meh.png" alt="">

                <p class="carousel-text">
                    Спасибо всей команде за&nbsp;то, что создали такой продукт, предложили его нам, продвигаете и&nbsp;постоянно совершенствуете! <br>
                    За&nbsp;несколько месяцев вы&nbsp;перешли от&nbsp;простой платформы к&nbsp;полноценному сервису с&nbsp;необходимым для e-comm инструментами для коммуникаций с&nbsp;покупателями: email- и&nbsp;push-рассылками, триггерными цепочками. Удобный и&nbsp;функциональный интерфейс, полноценная интеграция с&nbsp;сайтом&nbsp;&mdash; все это позволяет без особых усилий и&nbsp;временных затрат создать продукт, который реально приносит заказы. <br>
                    Также хочется отметить быструю реакцию техподдержки на&nbsp;наши вопросы и&nbsp;предложения по&nbsp;совершенствованию платформы. Мы&nbsp;рады, что многие наши идеи были реализованы, а&nbsp;мы&nbsp;смогли поучаствовать в&nbsp;повышении эффективности и&nbsp;функциональности вашего продукта.
                    Удачи вам и&nbsp;процветания!
                </p>

                <p class="carousel-author">
                    Константин Паршуков
                </p>

                <p class="carousel-author carousel-author--prof">
                    Руководитель интернет-магазина mosmexa.ru
                </p>
            </div>
            <div class="carousel-cell">
                <img class="carousel-logo" src="/assets/img/partners/brandshop.svg" alt="">

                <p class="carousel-text">
                    Будем лаконичны&nbsp;&mdash; мы&nbsp;довольны совместными результатами с&nbsp;командой enKod и&nbsp;собираемся дальше продолжить нашу продуктивную работу.
                </p>

                <p class="carousel-author">
                    Brandshop.ru
                </p>

                <p class="carousel-author carousel-author--prof">
                    Магазин брендовой одежды и обуви
                </p>
            </div>
            <div class="carousel-cell">
                <img class="carousel-logo" src="/assets/img/partners/stimul.svg" alt="">

                <p class="carousel-text">
                    Спасибо за&nbsp;классный продукт и&nbsp;качественную оперативную поддержку, готовность всегда помочь и&nbsp;дружелюбно ответить на&nbsp;самые глупые вопросы, внимание к&nbsp;потребностям пользователей и&nbsp;доброжелательность. Теперь мы&nbsp;экономим уйму времени и&nbsp;человеческих ресурсов на&nbsp;работу с&nbsp;частными донорами и&nbsp;подписчиками. Еще ребята помогли нам сделать крутейший дизайн для писем, и&nbsp;отправлять их&nbsp;теперь&nbsp;&mdash; одно удовольствие. Искренне рады сотрудничеству с&nbsp;enKod!
                </p>

                <div class="carousel-authot-box">
                    <img src="/assets/img/partners/kate-stimul.png" alt="">

                    <div>
                        <p class="carousel-author">
                            Екатерина Светашкова
                        </p>
        
                        <p class="carousel-author carousel-author--prof">
                            Проектная деятельность ЛГБТ-группы «Стимул»
                        </p>
                    </div>
                </div>
            </div>
        </carousel>
    </div>


    <p class="left-text opasity-ani">С&nbsp;кем мы&nbsp;работаем?</p>

    <div class="partners-box opasity-ani">
        <a class="partner partner--link" href="https://blog.enkod.io/kejs-brandshop-kak-s-pomoshhyu-tovarnyh-rekomendaczij-my-uvelichili-pribyl-s-sajta-na-59/" target="_blank">
            <img class="partner__logo partner__logo--small" src="/assets/img/partners/brandshop.png" alt="">
            <img class="partner__case" src="/assets/img/partners/case.png" alt="">
        </a>

        <a class="partner partner--link" href="https://blog.enkod.io/primer-idealnogo-lid-magnita-ili-kak-ast-podarili-34-000-knig/" target="_blank">
            <img class="partner__logo partner__logo--small" src="/assets/img/partners/ast.png" alt="">
            <img class="partner__case" src="/assets/img/partners/case.png" alt="">
        </a>

        <a target="_blank" class="partner partner--link" href="https://blog.enkod.io/kak-pravozashhitnaya-lgbt-inicziativnaya-gruppa-stimul-ekonomit-22-chasa-v-mesyacz-na-rassylkah/" >
            <div>
                <img class="partner__logo partner__logo--small" src="/assets/img/partners/stimul.svg" alt="">
                <img class="partner__case" src="/assets/img/partners/case.png" alt="">
            </div>  
        </a>

        <a target="_blank" class="partner partner--link" href="https://blog.enkod.io/kak-eksmo-uvelichili-klikabelnost-v-2-raza/" >
            <div>
                <img class="partner__logo partner__logo--small" src="/assets/img/partners/Exmo_Logo.svg" alt="">
                <img class="partner__case" src="/assets/img/partners/case.png" alt="">
            </div>  
        </a>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/lingualeo.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/unicom24.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/cvetnoe.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/mosk_meh.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/sem_doc.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/simple_wine.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/gala_centr.svg" alt="">
        </div>

        <!-- <a class="partner partner--link" href="https://cosmetika.ru/" target="_blank">
          <img class="partner__logo" src="/assets/img/partners/premium.svg" alt="">
          <img class="partner__case" src="/assets/img/partners/case.png" alt="">
        </a> -->

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/premium.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/market_sveta.svg" alt="">
        </div>

        <!-- <a class="partner partner--link" href="https://www.mothercare.ru/" target="_blank">
          <img class="partner__logo" src="/assets/img/partners/mothercare.svg" alt="">
          <img class="partner__case" src="/assets/img/partners/case.png" alt="">
        </a> -->

        <!-- <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/mothercare.svg" alt="">
        </div> -->

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/creditexp.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/divan_lait.svg" alt="">
        </div>

        <!-- <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/prime.svg" alt="">
        </div> -->

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/mailfit.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/lazurit.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/randewoo.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/design_boom.svg" alt="">
        </div>

        <div class="partner">
            <img class="partner__logo" src="/assets/img/partners/indust_krasoti.svg" alt="">
        </div>

        

        
    </div>
</div>
