
<section class="first-screen">
  <div class="main-grid">
      <div class="main-text-block">
          <h1 class="main-text-block__title">
            ИНСТРУМЕНТЫ ДЛЯ&nbsp;<span class="block__title-yellow">УМНОГО МАРКЕТИНГА</span>
          </h1>
          <p class="main-text-block__subtitle">
            Представьте себе идеальный сервис, в&nbsp;котором собраны все нужные инструменты для автоматизации маркетинга. А&nbsp;еще команда, которая заботится о&nbsp;клиентах и&nbsp;отвечает в&nbsp;течение 20&nbsp;минут&nbsp;&mdash; это enKod.
          </p>

      </div>
      <div class="mac-container">
          <img src="/assets/img/main/Macbook.png" alt="" class="macbook">
          <div class="mac-container__screen">
              <!-- <div class="mac-container__white-screen"></div> -->
           
              <!-- <svg class="root-loading-img" width="150" height="150"  viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="150" height="150" fill="black"/>
                <path d="M176.733 219.544C176.733 219.544 176.733 217.354 184.258 217.354C192.19 217.354 192.015 219.544 192.015 219.544C191.434 236.386 172.777 250.863 148.786 250.863C120.239 250.863 101 232.432 101 205.067V194.776C101 167.431 120.239 149 147.002 149C172.777 149 192.209 168.012 192.209 194.776V202.102C192.209 202.102 192.209 204.68 185.072 204.68H118.222V208.245C118.222 227.277 130.731 237.975 148.767 237.975C165.232 237.975 176.733 228.653 176.733 219.544ZM147.778 162.469C131.119 162.469 118.222 173.574 118.222 192.586V193.381H176.927C176.326 173.768 163.254 162.469 147.778 162.469Z" fill="white"/>
                <path d="M298.432 247.682C298.432 247.682 298.432 250.454 289.705 250.454C280.978 250.454 281.172 247.682 281.172 247.682V185.665C281.172 185.665 281.172 162.874 257.182 162.874H228.227V247.702C228.227 247.702 228.227 250.473 219.499 250.473C210.772 250.473 210.966 247.702 210.966 247.702V153.552C210.966 153.552 214.728 149.385 257.182 149.385C298.432 149.385 298.432 185.646 298.432 185.646V247.682Z" fill="white"/>
                </svg> -->

                <!-- <img class="root-loading-img" src="/assets/img/main/enKod-loader.svg"> -->
                
              <!-- <img src="/assets/img/main/enpop-mac-icon.svg" alt="" class="mac-container__enpop-icon service-icon"> -->
              <!-- <img src="/assets/img/main/ensend-mac-icon.svg" alt="" class="mac-container__ensend-icon service-icon"> -->
              <!-- <img src="/assets/img/main/enrecom-mac-icon.svg" alt="" class="mac-container__enrecom-icon service-icon"> -->
          </div>
      </div>
      <div class="link-container">
        <a routerLink="demo" class="service-box__link">
          <p class="link__text">Демо</p>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M7.38414 10.613L13.8488 4.14837C14.0555 3.93435 14.0495 3.59332 13.8355 3.38662C13.6267 3.18498 13.2958 3.18498 13.087 3.38662L7.00326 9.4704L0.919491 3.38662C0.709138 3.1763 0.368101 3.1763 0.157748 3.38662C-0.0525742 3.59701 -0.0525742 3.93801 0.157748 4.14837L6.62239 10.613C6.83278 10.8233 7.17378 10.8233 7.38414 10.613Z" fill="#ffffff"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="14" height="14" fill="white" transform="translate(14) rotate(90)"/>
            </clipPath>
            </defs>
          </svg>
        </a>
      </div>

  </div>

</section>
<section class="second-screen">
  <div class="main-grid">
      <h2 class="scroll-title scroll-title1">
          Зачем мне enKod?
      </h2>
      <div class="scroll-text-container">
          <p class="scroll-text scroll-text1">
            Наше приложение поможет вам навести порядок в&nbsp;коммуникациях с&nbsp;клиентами. Мы&nbsp;предоставляем для этого весь необходимый функционал. Сбор базы; отправка массовых или триггерных мультиканальных рассылок; трекинг действий пользователей; товарные рекомендации на&nbsp;сайте, в&nbsp;приложении или в&nbsp;email.
          </p>
      </div>
     <div class="logos-container">
         <img class="logos-container__logo logo1" src="/assets/img/main/logo1.svg" alt="">
         <img class="logos-container__logo logo2" src="/assets/img/main/logo2.svg" alt="">
         <img class="logos-container__logo logo3" src="/assets/img/main/logo3.svg" alt="">
         <img class="logos-container__logo logo4" src="/assets/img/main/logo4.svg" alt="">
         <!-- <img class="logos-container__logo logo5" src="/assets/img/main/logo5.png" alt=""> -->
     </div>
  </div>
</section>
<section class="third-screen">
  <div class="main-grid">
      <h2 class="scroll-title scroll-title2">
          Наши сервисы
      </h2>
      <div class="scroll-text-container scroll-text-container2">
          <div class="scroll-text-notepad-block enrecom-notepad-block">
            <img class="scroll-text-notepad-logo notepad-logo--enrecom" src="/assets/img/main/R.svg" alt="">
              <!-- <svg class="scroll-text-notepad-logo notepad-logo--enrecom" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.3152 49.3341H8.97731C7.6984 49.3341 6.66602 48.3017 6.66602 47.0228L6.66602 27.685C6.66602 26.406 7.6984 25.3737 8.97731 25.3737L28.3152 25.3737C29.5941 25.3737 30.6265 26.406 30.6265 27.685L30.6265 47.0228C30.6188 48.294 29.5864 49.3341 28.3152 49.3341Z" fill="#0079FF"/>
                  <path d="M28.889 19.0783H19.9366C18.9813 19.0783 18.2031 18.3002 18.2031 17.3448V8.4001C18.2031 7.44476 18.9813 6.66663 19.9366 6.66663L28.8813 6.66663C29.8367 6.66663 30.6148 7.44476 30.6148 8.4001V17.3448C30.6148 18.3079 29.8444 19.0783 28.889 19.0783Z" fill="#80BCFF"/>
                  <path d="M47.5863 37.7846H38.6339C37.6785 37.7846 36.9004 37.0065 36.9004 36.0511V27.1064C36.9004 26.1511 37.6785 25.3729 38.6339 25.3729H47.5863C48.5416 25.3729 49.3198 26.1511 49.3198 27.1064V36.0511C49.3198 37.0065 48.5416 37.7846 47.5863 37.7846Z" fill="#80BCFF"/>
                  <path d="M47.5863 19.0792H38.6339C37.6785 19.0792 36.9004 18.301 36.9004 17.3457V8.40095C36.9004 7.44562 37.6785 6.66748 38.6339 6.66748L47.5863 6.66748C48.5416 6.66748 49.3198 7.44562 49.3198 8.40095V17.3457C49.3198 18.3087 48.5416 19.0792 47.5863 19.0792Z" fill="#BFDDFF"/>
              </svg> -->
              <p class="scroll-text scroll-text--enrecom">
                enRecom&nbsp;&mdash; поможет создать товарные рекомендации, которые точно заинтересуют ваших клиентов. Вам не&nbsp;нужно разбираться в&nbsp;нейронных сетях и&nbsp;сложных алгоритмах, мы&nbsp;уже сделали это для вас.
              </p>
              <a target="_blank" href="https://enrecom.io/?utm_source=site-enKod&utm_medium=glavnaya-enKod&utm_campaign=enRecom" class="scroll-link scroll-link--enrecom">Сайт enRecom</a>
          </div>
          <div class="scroll-text-notepad-block ensend-notepad-block">
            <img class="scroll-text-notepad-logo notepad-logo--ensend" src="/assets/img/main/S.svg" alt="">
              <!-- <svg class="scroll-text-notepad-logo notepad-logo--ensend" width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.1834 26.3715H7.23447C6.18719 26.3715 5.3418 25.5273 5.3418 24.4816V8.55659C5.3418 7.51088 6.18719 6.66675 7.23447 6.66675H23.1834C24.2307 6.66675 25.0761 7.51088 25.0761 8.55659V24.4816C25.0761 25.521 24.2244 26.3715 23.1834 26.3715Z" fill="#1DC296"/>
                  <path d="M48.0619 49.3334H32.1192C31.072 49.3334 30.2266 48.4893 30.2266 47.4436V31.5185C30.2266 30.4728 31.072 29.6287 32.1192 29.6287H48.0682C49.1155 29.6287 49.9609 30.4728 49.9609 31.5185V47.4436C49.9546 48.4893 49.1092 49.3334 48.0619 49.3334Z" fill="#1DC296"/>
                  <path d="M43.7838 21.6219H36.3961C35.6138 21.6219 34.9766 20.9856 34.9766 20.2045V12.8341C34.9766 12.053 35.6138 11.4167 36.3961 11.4167H43.7838C44.5661 11.4167 45.2033 12.053 45.2033 12.8341V20.2108C45.2033 20.9856 44.5661 21.6219 43.7838 21.6219Z" fill="#77DAC0"/>
                  <path d="M18.9181 44.6277H11.5367C10.7544 44.6277 10.1172 43.9914 10.1172 43.2103V35.8336C10.1172 35.0525 10.7544 34.4163 11.5367 34.4163H18.9181C19.7004 34.4163 20.3376 35.0525 20.3376 35.8336V43.2103C20.3376 43.9914 19.7067 44.6277 18.9181 44.6277Z" fill="#77DAC0"/>
                </svg> -->

              <p class="scroll-text scroll-text--ensend">
                enSend&nbsp;&mdash; общайтесь с&nbsp;клиентами там, где им&nbsp;удобно и&nbsp;не&nbsp;тратьте время на&nbsp;ручную работу. Выберите канал коммуникации, настройте сценарии отправки сообщений и&nbsp;следите за&nbsp;результатами в&nbsp;удобных отчетах.
              </p>
              <a target="_blank" href="https://ensend.io/?utm_source=site-enKod&utm_medium=glavnaya-enKod&utm_campaign=enSend" class="scroll-link scroll-link--ensend">Сайт enSend</a>
          </div>
          <div class="scroll-text-notepad-block enrecom-notepad-block">
            <img class="scroll-text-notepad-logo notepad-logo--enpop" src="/assets/img/main/P.svg" alt="">
              <!-- <svg class="scroll-text-notepad-logo notepad-logo--enpop" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M48.0736 30.4734L35.2478 30.4734C33.0712 30.4734 31.3223 28.6852 31.3223 26.4599L31.3223 13.3463C31.3223 11.121 33.0712 9.33276 35.2478 9.33276L48.0736 9.33276C50.2501 9.33276 51.9991 11.121 51.9991 13.3463V26.4599C51.9991 28.6852 50.2501 30.4734 48.0736 30.4734Z" fill="#FF2856"/>
                  <path opacity="0.6" d="M26.6939 47.203H19.5425C17.5603 47.203 15.9668 45.5737 15.9668 43.5471L15.9668 36.2353C15.9668 34.2086 17.5603 32.5793 19.5425 32.5793H26.6939C28.6761 32.5793 30.2696 34.2086 30.2696 36.2353V43.5471C30.2696 45.5737 28.6761 47.203 26.6939 47.203Z" fill="#FF2856"/>
                  <path opacity="0.25" d="M8.85633 30.7519C6.17456 30.7519 3.99805 28.5266 3.99805 25.7846C3.99805 23.0427 6.17456 20.8174 8.85633 20.8174C11.5381 20.8174 13.7146 23.0427 13.7146 25.7846C13.7146 28.5266 11.5381 30.7519 8.85633 30.7519Z" fill="#FF2856"/>
              </svg> -->

              <p class="scroll-text scroll-text--enpop">
                enPop собирает данные о&nbsp;поведении ваших клиентов на&nbsp;сайте. А&nbsp;еще здесь можно создать всплывающие окна, которые не&nbsp;раздражают пользователей и&nbsp;помогают быстро собрать базу контактов.
              </p>
              <a target="_blank" href="https://enpop.ru/?utm_source=site-enKod&utm_medium=glavnaya-enKod&utm_campaign=enPop" class="scroll-link scroll-link--enpop">Сайт enPop</a>
          </div>
      </div>
  </div>
  <div class="product-grid">
      <div class="product-block">
          <img class="right-ellipse" src="/assets/img/main/Ellipse.svg" alt="">
          <svg class="ellipse-line"  width="33" height="243" viewBox="0 0 33 243" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="qwe" d="M30.5003 0.5C7.66699 40.3333 -22.8 143.7 32 242.5" stroke="white"/>
          </svg>

          <span class="product-grid__name name--enrecom">enRecom</span>
          <span class="product-grid__name name--ensend">enSend</span>
          <span class="product-grid__name name--enpop">enPop</span>
          <div class="product-block__service service-enrecom">
              <img class="product-block__logo" src="/assets/img/main/R.svg" alt="enRecom">
          </div>
          <div class="product-block__service service-ensend">
              <img class="product-block__logo" src="/assets/img/main/S.svg" alt="enSend">
          </div>
          <div class="product-block__service service-enpop">
              <img class="product-block__logo" src="/assets/img/main/P.svg" alt="enSend">
          </div>
      </div>
  </div>
</section>

<section class="fourth-screen">
  <div class="main-grid">
      <h2  #test class="scroll-title scroll-title3">
          enKod выбирают,  <br>
          потому что:
      </h2>
      <div class="reasons reasons--left reason1">
          <div class="reasons__head">
              <img class="reasons__img" src="/assets/img/main/reasons-1.svg" alt="">
              <h3 class="reasons__title">Все в одном окне</h3>
          </div>
          <span class="reasons__text">
            И&nbsp;отчеты по&nbsp;всем каналам тоже. А&nbsp;еще готовые сценарии и&nbsp;триггеры. Бери и&nbsp;запускай.
          </span>
      </div>
      <div class="reasons reasons--right reason2">
          <div class="reasons__head">
              <img class="reasons__img" src="/assets/img/main/reasons-2.svg" alt="">
              <h3 class="reasons__title">Простой интерфейс</h3>
          </div>
          <span class="reasons__text">
            Настолько, что вам не&nbsp;нужно месяцами обучать своих сотрудников. Посмотрите и&nbsp;убедитесь сами.
          </span>
      </div>
      <div class="reasons reasons--left reason3">
          <div class="reasons__head">
              <img class="reasons__img" src="/assets/img/main/reasons-3.svg" alt="">
              <h3 class="reasons__title">Понятный результат</h3>
          </div>
          <span class="reasons__text">
            Собирайте базу, автоматизируйте рассылки и&nbsp;получайте более&nbsp;30% оборота с&nbsp;повторных продаж.
          </span>
      </div>
      <div class="reasons reasons--right reason4">
          <div class="reasons__head">
              <img class="reasons__img" src="/assets/img/main/reasons-4.svg" alt="">
              <h3 class="reasons__title">Крутой кейс</h3>
          </div>
          <span class="reasons__text">
            Мы&nbsp;не&nbsp;только поможем все настроить, но&nbsp;еще сделаем вам рекламу&nbsp;&mdash; напишем статью о&nbsp;результатах с&nbsp;вашим фото.
          </span>
      </div>
      <!-- <div class="link-container margin-top">
        <a routerLink="demo" class="service-box__link">
          <p class="link__text">Демо</p>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
            <path d="M7.38414 10.613L13.8488 4.14837C14.0555 3.93435 14.0495 3.59332 13.8355 3.38662C13.6267 3.18498 13.2958 3.18498 13.087 3.38662L7.00326 9.4704L0.919491 3.38662C0.709138 3.1763 0.368101 3.1763 0.157748 3.38662C-0.0525742 3.59701 -0.0525742 3.93801 0.157748 4.14837L6.62239 10.613C6.83278 10.8233 7.17378 10.8233 7.38414 10.613Z" fill="#ffffff"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="14" height="14" fill="white" transform="translate(14) rotate(90)"/>
            </clipPath>
            </defs>
          </svg>
        </a>
      </div> -->
  </div>
</section>
<!-- <section class="fifth-screen">
  <div class="main-grid">
      <h2 class="scroll-title scroll-title4">
          Рекомендуемые
          статьи
      </h2>
      <div class="blog reasons--left blog1">
          <div class="reasons__head">
              <h3 class="reasons__title">Руководство пользователя</h3>
          </div>
          <span class="reasons__text text--gray">
              Здесь находится описание всего функционала системы enSend, который поможет реализовать ваши маркетинговые задачи.
          </span>
          <span class="blog__date">12 июня 2020</span>
      </div>
      <div class="blog reasons--right blog2">
          <div class="reasons__head">
              <h3 class="reasons__title">Документация по API</h3>
          </div>
          <span class="reasons__text text--gray">
              Здесь находится описание всего функционала системы enSend, который поможет реализовать ваши маркетинговые задачи.
          </span>
          <span class="blog__date">12 июня 2020</span>
      </div>
      <div class="blog reasons--left blog3">
          <div class="reasons__head">
              <h3 class="reasons__title">Документация по API</h3>
          </div>
          <span class="reasons__text text--gray">
              Нужно настроить автоматическую передачу контактов в&nbsp;enSend? Или хочется отправлять сообщения.
          </span>
          <span class="blog__date">12 июня 2020</span>
      </div>
      <div class="blog reasons--right blog4">
          <div class="reasons__head">
              <h3 class="reasons__title">Документация по API</h3>
          </div>
          <span class="reasons__text text--gray">
              Нужно настроить автоматическую передачу контактов в&nbsp;enSend? Или хочется отправлять сообщения.
          </span>
          <span class="blog__date">12 июня 2020</span>
      </div>
      <a href="#" class="blog__link">Перейти в блог enKod</a>
  </div>
</section> -->
