<div class="main-grid">
  <h1 class="title-polit">СОГЛАСИЕ НА&nbsp;ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ САЙТОВ</h1>

  <h2 class="polit-title">Персональные данные под защитой</h2>

  <p class="polit-text">
    Настоящим я&nbsp;свободно, своей волей и&nbsp;в&nbsp;своем интересе даю согласие на&nbsp;то, что передаваемые мной персональные данные, могут обрабатываться. <br><br>

    Под обработкой понимается: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. Указанные действия совершаются как с&nbsp;использованием средств автоматизации, так и&nbsp;без использования таких средств. <br><br>

    Состав передаваемых персональных данных: Фамилия, имя, отчество, номер телефона, адрес электронной почты, а&nbsp;также данные об&nbsp;интернет-сеансе и&nbsp;интернет-устройстве (производитель, модель, версия операционной системы, идентификатор устройств и&nbsp;иные технические параметры и&nbsp;идентификаторы, в&nbsp;т.ч. IMEI, ip-адрес, а&nbsp;также файлы cookie) иные персональные данные передаваемые мной. <br><br>

    Биометрические и&nbsp;специальные персональные данные Оператором не&nbsp;обрабатываются. <br><br>

    Целью обработки является организация пользования Интернет-ресурсами Оператора и&nbsp;приложениями, взаимодействие со&nbsp;мной в&nbsp;ходе такого пользования, осуществления уставной (коммерческой) деятельности Оператора. Правовым основанием цели обработки персональных данных является&nbsp;ст.&nbsp;6&nbsp;Федерального закона &laquo;152-ФЗ &laquo;О&nbsp;персональных данных&raquo;, настоящее согласие на&nbsp;обработку персональных данных и&nbsp;Устав ООО &laquo;ЭНКОД&raquo;. <br><br>

    Настоящее согласие дано Обществу с&nbsp;ограниченной ответственностью &laquo;ЭНКОД&raquo; ОГРН: 1166234079868 (&laquo;Оператор&raquo;), в&nbsp;вышеуказанных целях. <br><br>

    Срок действия настоящего согласия распространяется на&nbsp;период пользования Субъектом персональных данных настоящим Интернет-ресурсом и&nbsp;связанными с&nbsp;ним сервисами (сайтами, приложениями) вплоть до&nbsp;достижения целей обработки или до&nbsp;утраты необходимости в&nbsp;достижении этих целей, если иное не&nbsp;предусмотрено федеральным законом и&nbsp;в&nbsp;течении&nbsp;3 (трех) лет после достижения указанных целей, либо утраты необходимости в&nbsp;их&nbsp;достижении. <br><br>

    Настоящее Согласие на&nbsp;обработку персональных данных может отозвано Вами полностью или частично в&nbsp;любой момент посредством направления письменного запроса на&nbsp;почтовый адрес Оператора 390027, Россия, Рязань, Кальная улица, дом&nbsp;5, помещение Н48 либо электронную почту experts@enkod.ru, также на&nbsp;данный почтовый адрес вы&nbsp;можете направить запрос на&nbsp;получение любой информации, касающейся обработки Ваших персональных данных. <br><br>

    В&nbsp;случае отзыва субъектом персональных данных или его представителем согласия на&nbsp;обработку персональных данных ООО &laquo;ЭНКОД&raquo; вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в&nbsp;пунктах 2-11 части 1&nbsp;статьи&nbsp;6, части 2&nbsp;статьи 10&nbsp;и&nbsp;части 2&nbsp;статьи 11&nbsp;Федерального закона <span style="font-family: Raleway,sans-serif; font-weight: 300;">&#8470;</span>&nbsp;152-ФЗ &laquo;О&nbsp;персональных данных&raquo; от&nbsp;27.07.2006. <br><br>

    Оператор обязуется обеспечить конфиденциальность Ваших персональных данных за&nbsp;исключением случаев, предусмотренных законодательством Российской Федерации. <br><br>

    Персональные данные не&nbsp;являются общедоступными. <br><br>

    Достоверность и/или правильность предоставляемых вами персональных данных не&nbsp;проверяется Оператором. Любые действия совершенные вами, считаются совершенными определенным физическим лицом (вами) пока не&nbsp;доказано иное. Оператор не&nbsp;несет ответственности за&nbsp;последствия, вызванные предоставлением вами недостоверных и/или ошибочных персональных данных.
  </p>
</div>
