<div class="main-grid">
  <div class="error-box">
    <img class="error-pic" src="/assets/img/404/error-pic.svg" alt="">

    <h1 class="title">404</h1>

    <p class="error-text">Ой, кажется вы&nbsp;потерялись</p>

    <a class="error-button" routerLink="/">
      <p class="button-text">Верните меня обратно</p>
      <img class="button-arrow" src="/assets/img/404/error-arrow.svg" alt="">
    </a>
  </div>
</div>
