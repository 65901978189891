    <div class="main-grid">
        <div class="title-box">
            <h1 class="title">О команде</h1>
        </div>

        <p class="what-is-title opasity-ani">Что такое enKod?</p>

        <div class="what-is-box opasity-ani">
            <p class="what-is-box__text what-is-box__text--mb"><span>en</span>&nbsp;&mdash; инициалы Элеоноры Никифоровой&nbsp;&mdash; нашей основательницы, идейного вдохновителя и&nbsp;стратега.</p>

            <p class="what-is-box__text"><span>Kod</span>&nbsp;&mdash; это команда экспертов, разработчиков, дизайнеров, аналитиков, верстальщиков и&nbsp;тестировщиков, объединенных идеей создать самые классные и&nbsp;удобные сервисы для вашего бизнеса.</p>
        </div>

        <img data-aos="fade-up" data-aos-once="true" class="pic1 opasity-ani" src="/assets/img/about-team/pic1.png" alt="">

        <img data-aos="fade-up" data-aos-once="true" class="pic2 opasity-ani" src="/assets/img/about-team/pic2.png" alt="">

        <img data-aos="fade-up" data-aos-once="true" class="pic3 opasity-ani" src="/assets/img/about-team/pic3.png" alt="">

        <img data-aos="fade-up" data-aos-once="true" class="elya-foto opasity-ani" src="/assets/img/about-team/elya.png" alt="">

        <div data-aos="fade-up" data-aos-once="true" class="elya-box opasity-ani">
            <div class="elya-box__title">
                <p class="elya-box__name">Элеонора Никифорова</p>
                <p class="elya-box__prof">Главный вдохновитель enKod</p>
            </div>

            <p class="elya-box__text elya-box__text--mb">Моя цель&nbsp;&mdash; собрать классную команду и&nbsp;вместе с&nbsp;ней создавать максимально удобные и&nbsp;понятные для маркетологов технологические продукты.</p>

            <p class="elya-box__text">Мне можно написать на&nbsp;почту <a target="_blank" class="elya-box__mail" href="mailto:ceo@enkod.io">ceo@enkod.io</a>, и&nbsp;я&nbsp;отвечу вам сразу&nbsp;же, как у&nbsp;меня появится минутка 😊 Я&nbsp;всегда рада конструктивным рекомендациям по&nbsp;развитию продукта, но&nbsp;буду разочарована увидеть бездумно скопированный продающий текст.</p>
        </div>

        <app-team class="team opasity-ani"></app-team>

        <!-- <div class="team opasity-ani">
          <div class="team-box">
            <p class="team-title">Лица <br> команды enKod</p>

            <div class="photo-box">
              <a routerLink="../elle.nikiforova" class="worker">
                <img class="worker-photo" src="/assets/img/about-team/elleN.jpg" alt="">
                <p class="worker-name">Элеонора Никифорова</p>
                <p class="worker-place">Главный вдохновитель</p>
              </a>

              <a routerLink="../kristina.markina" class="worker">
                <img class="worker-photo" src="/assets/img/about-team/krisM.jpg" alt="">
                <p class="worker-name">Кристина Маркина</p>
                <p class="worker-place">Коммерческий директор</p>
              </a>

              <a routerLink="../alexander.chernov" class="worker">
                <img class="worker-photo" src="/assets/img/about-team/alexCh.jpg" alt="">
                <p class="worker-name">Александр Чернов</p>
                <p class="worker-place">Руководитель отдела HR</p>
              </a>

              <a routerLink="../maxim.tishininov" class="worker">
                <img class="worker-photo" src="/assets/img/about-team/maximT.jpg" alt="">
                <p class="worker-name">Максим Тишиминов</p>
                <p class="worker-place">Финансовый директор</p>
              </a>

              <a routerLink="../artur.kharitonov" class="worker">
                <img class="worker-photo" src="/assets/img/about-team/arturKh-color.jpg" alt="">
                <p class="worker-name">Артур Харитонов</p>
                <p class="worker-place">Руководитель отдела HR</p>
              </a>
            </div>
          </div> -->

        <!-- <div class="team">
          <mat-tab-group dynamicHeight animationDuration="460ms" [disableRipple]="true">
            <mat-tab label="Руководители">
              <div class="team-box">
                <p class="team-title">Руководители отделов</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/dima-c.png" alt="">
                    <p class="worker-name">Дмитрий Кондрахин</p>
                    <p class="worker-place">Технический директор</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/olga-m.png" alt="">
                    <p class="worker-name">Ольга Меркулова</p>
                    <p class="worker-place">Финансовый директор</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/kris-m.png" alt="">
                    <p class="worker-name">Кристина Маркина</p>
                    <p class="worker-place">Коммерческий директор</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/kate-s.png" alt="">
                    <p class="worker-name">Екатерина Савельева</p>
                    <p class="worker-place">Руководитель отдела HR</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/max-e.png" alt="">
                    <p class="worker-name">Максим Ефремов</p>
                    <p class="worker-place">Руководитель отдела машинного обучения</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Back-end">
              <div class="team-box">
                <p class="team-title">Back-end разработка</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/dima-b.png" alt="">
                    <p class="worker-name">Дмитрий Барсуков</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/nestor-p.png" alt="">
                    <p class="worker-name">Нестор Поляков</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/alex-f.png" alt="">
                    <p class="worker-name">Александр Фирсов</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/nikita-s.png" alt="">
                    <p class="worker-name">Никита Сергиенко</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/poma-a.png" alt="">
                    <p class="worker-name">Роман Аванесов</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/ilya-g.png" alt="">
                    <p class="worker-name">Илья Грязнев</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Front-end">
              <div class="team-box">
                <p class="team-title">Front-end разработка</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/alex-s.png" alt="">
                    <p class="worker-name">Алексей Сучков</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/dima-s.png" alt="">
                    <p class="worker-name">Дмитрий Спиридонов</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/valera-s.png" alt="">
                    <p class="worker-name">Валерий Селезнев</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Внешние коммуникации">
              <div class="team-box">
                <p class="team-title">Внешние коммуникации</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/evgen-s.png" alt="">
                    <p class="worker-name">Евгений Солдатов</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Поддержка">
              <div class="team-box">
                <p class="team-title">Поддержка</p>

                <div class="photo-box">

                  <div class="worker">
                    <img src="/assets/img/about-team/max-r.png" alt="">
                    <p class="worker-name">Максим Родин</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/max-t.png" alt="">
                    <p class="worker-name">Максим Тишининов</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/alex-p.png" alt="">
                    <p class="worker-name">Александр Попель</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/nastya-a.png" alt="">
                    <p class="worker-name">Анастасия Аниськова</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Дизайн">
              <div class="team-box">
                <p class="team-title">UI/UX дизайнеры</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/nikita-f.png" alt="">
                    <p class="worker-name">Никита Федосеев</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Финансы">
              <div class="team-box">
                <p class="team-title">Отдел финансов</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/nastya-k.png" alt="">
                    <p class="worker-name">Анастасия Казакова</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/mariya-g.png" alt="">
                    <p class="worker-name">Мария Головастова</p>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Машинное обучение">
              <div class="team-box">
                <p class="team-title">Машинное обучение</p>

                <div class="photo-box">
                  <div class="worker">
                    <img src="/assets/img/about-team/nika-v.png" alt="">
                    <p class="worker-name">Ника Вишневская</p>
                  </div>

                  <div class="worker">
                    <img src="/assets/img/about-team/elena-a.png" alt="">
                    <p class="worker-name">Елена Азязова</p>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div> -->


    </div>
