<div
    class="event-catcher"
    (tuiActiveZoneChange)="onActiveZone($event)"
    (mousedown)="onMouseDown($event)"
    (touchstart)="onTouchStart($event)"
>
    <div class="wrapper">
        <div *ngIf="segmented" class="segments">
            <span
                *tuiRepeatTimes="let segmentIndex of segments + 1"
                class="segment"
                automation-id="tui-slider__segment"
            >
                <span class="number">
                    {{getSegmentPrefix(segmentIndex)}}
                    {{getSegmentLabel(segmentIndex) | tuiFormatNumber}}
                    <span *ngIf="isPluralized(pluralize)">
                        {{getSegmentLabel(segmentIndex) | tuiPluralize:
                        pluralize}}
                    </span>
                </span>
            </span>
        </div>
        <div
            class="bar"
            automation-id="tui-slider__bar"
            [style.left.%]="left"
            [style.right.%]="right"
        >
            <div
                #dotLeft
                class="dot"
                automation-id="tui-slider__left"
                [class.dot_focus-visible]="focusVisibleLeft && computedFocused"
                [tuiFocusable]="isLeftFocusable"
                (tuiFocusVisibleChange)="onLeftFocusVisible($event)"
                (keydown.arrowLeft.prevent)="decrement(false)"
                (keydown.arrowDown.prevent)="decrement(false)"
                (keydown.arrowRight.prevent)="increment(false)"
                (keydown.arrowUp.prevent)="increment(false)"
            ></div>
            <div
                #dotRight
                class="dot"
                automation-id="tui-slider__right"
                [class.dot_focus-visible]="focusVisibleRight && computedFocused"
                [tuiFocusable]="isRightFocusable"
                (tuiFocusVisibleChange)="onRightFocusVisible($event)"
                (keydown.arrowLeft.prevent)="decrement(true)"
                (keydown.arrowDown.prevent)="decrement(true)"
                (keydown.arrowRight.prevent)="increment(true)"
                (keydown.arrowUp.prevent)="increment(true)"
            ></div>
        </div>
    </div>
</div>
