<div class="main-grid ">
    <!-- <img data-aos="fade-up" data-aos-once="true" class="main-foto " src="/assets/img/people/alexCh.jpg" alt=""> -->

    <div class="video-div ">
      <video class="video-desc" autoplay muted loop playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="/assets/img/people/alexCh-video-desc.mp4">
        <source src="/assets/img/people/alexCh-video-desc.webm">
      </video>
    </div>

    <div class="video-box qweqwe" >
      <video class="video" autoplay muted loop playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="/assets/img/people/alexCh-video.mp4">
        <source src="/assets/img/people/alexCh-video.webm">
      </video>

      <div class="name-box name-box--for-video">
          <p class="name__man">Александр Чернов</p>
          <p class="name__prof">Менеджер проекта</p>
      </div>
    </div>


    <div data-aos="fade-up" data-aos-once="true" class="content-box ">
        <div class="name-box">
            <p class="name__man">Александр Чернов</p>
            <p class="name__prof">Менеджер проекта</p>
        </div>

        <div class="buttons-box">
          <div class="write-box ">
            <div class="write-button" (click)="buttonClick()" [ngClass] = "buttonStatus ? 'write-button--active' : '' ">
              <p class="write__text">Написать мне</p>

              <svg class="write__pic" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                <path d="M10.6932 6.65686L4.1499 0.271794C3.93337 0.0677357 3.59244 0.0778419 3.38838 0.29437C3.18931 0.505596 3.19336 0.836537 3.39754 1.0428L9.55529 7.05169L3.5464 13.2094C3.33867 13.4223 3.34284 13.7634 3.55572 13.9711C3.76866 14.1789 4.10964 14.1747 4.3174 13.9618L10.7025 7.41855C10.9102 7.20561 10.906 6.86463 10.6932 6.65686Z" />
                </g>
              </svg>
            </div>

            <a target="_blank" class="soc-img" href="mailto:alexander.chernov@enkod.io"><img src="/assets/img/people/mail.svg" alt=""></a>
            <a target="_blank" class="soc-img soc-img--fb" href="https://www.facebook.com/profile.php?id=100010021723478"><img src="/assets/img/people/fb.svg" alt=""></a>
            <a target="_blank" class="soc-img soc-img--teleg" href="https://t.me/alexan24"><img src="/assets/img/people/teleg.svg" alt=""></a>
            <a target="_blank" class="soc-img soc-img--phone" href="https://wa.me/79997648988"><img src="/assets/img/people/phone.svg" alt=""></a>
          </div>

          <a class="add-button" href="/assets/img/people/alexander-chernov.vcf" download>
            <img src="/assets/img/people/add.svg" alt="">

            <p>Добавить в контакты</p>
          </a>
        </div>

        <p [ngClass]="{'man__text--mt': buttonStatus === true}" class="man__text man__text--mb">Привет! <br>
          Я&nbsp;менеджер проекта enKod. Моя задача&nbsp;&mdash; спроектировать платформу, в&nbsp;которой нуждаются наши клиенты. С&nbsp;удовольствием расскажу о&nbsp;наших планах и&nbsp;грядущих обновлениях. </p>

        <p class="man__text man__text--mb">Знаю о&nbsp;всех возможных интеграциях enKod. Подскажу, с&nbsp;чем умеем интегрироваться, насколько сложный процесс, сколько времени занимает и&nbsp;с&nbsp;кем уже делали подобную работу. Если у&nbsp;вас есть какие-то идеи или пожелания по&nbsp;функционалу нашей платформы, пишите мне, и&nbsp;мы&nbsp;их&nbsp;обсудим 😉</p>
        <p class="man__text man__text--about">В&nbsp;свободное от&nbsp;работы время играю на&nbsp;пианино или на&nbsp;гитаре. Веду активный образ жизни, занимаюсь спортом и&nbsp;путешествую 😊</p>
    </div>

    <app-team class="team opasity-ani"></app-team>
  </div>
