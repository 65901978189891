import { FormControl, FormGroup } from '@angular/forms';
import { Renderer2, inject, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {
    title.setTitle('Цена enKod — платформы для автоматизации маркетинга');
    meta.addTags([
      {
        name: 'description',
        content:
          'Онлайн калькулятор для рассчета стоимости подключения платформы enKod. Выбираете необходимые модули, нужные опции и оставляете заявку. ',
      },
      {
        name: 'robots',
        content: 'noindex, nofollow',
      },
      {
        name: 'googlebot',
        content: 'noindex, nofollow',
      },
      {
        name: 'yandex',
        content: 'none',
      },
    ]);
  }

  basePrice = 0;
  contactsPrice = 25000;
  messagePrice = 0;
  similarProductsCoast = 0;
  ensendCoast = 0;
  personalManagerCoast = 0;
  smsSendCoast = 0;
  enrecomCoast = 0;
  trackmoduleCoast = 0;
  whatsappCoast = 0;

  addedService = {
    ensendModule: false,
    tracking: false,
    enrecomModule: false,
    personalManager: false,
    similarProducts: false,
    smsSend: false,
    dbModule: false,
    trackModule: false,
    messageModule: false,
    whatsapp: false,
  };

  maskNumber = {
    mask: Number,
    thousandsSeparator: ' ',
    max: '999999999999999',
    min: '-999999999999999',
  };

  addCoast() {
    if (this.addedService.enrecomModule)
      this.addedService.similarProducts = false;

    if (this.addedService.tracking && !this.addedService.enrecomModule) {
      this.ensendCoast = 10000;
    } else {
      this.ensendCoast = 0;
    }

    if (this.addedService.enrecomModule) {
      this.enrecomCoast = 38750;
    } else if (this.addedService.similarProducts) {
      this.enrecomCoast = 18750;
    } else {
      this.enrecomCoast = 0;
    }

    if (this.addedService.personalManager) {
      this.personalManagerCoast = 26000;
    } else {
      this.personalManagerCoast = 0;
    }

    if (this.addedService.smsSend) {
      this.smsSendCoast = 6250;
    } else {
      this.smsSendCoast = 0;
    }

    if (this.addedService.trackModule) {
      this.trackmoduleCoast = 20000;
    } else {
      this.trackmoduleCoast = 0;
    }

    if (this.addedService.whatsapp) {
      this.whatsappCoast = 5000;
    } else {
      this.whatsappCoast = 0;
    }
  }

  addTracking() {
    if (this.addedService.enrecomModule) {
      this.addedService.tracking = true;
      return;
    }
    this.addedService.tracking = !this.addedService.tracking;
    this.ensendCoast = 0;
  }

  addDbModuleCoast() {
    if (this.addedService.dbModule) {
      this.contactsPrice = 16000;
    } else {
      this.contactsPrice = 0;
    }
  }

  addMessageCoast() {
    if (this.addedService.messageModule) {
      this.messagePrice = 21000;
    } else {
      this.messagePrice = 0;
    }
  }

  cancelTrack() {
    this.addedService.tracking = false;
    this.trackmoduleCoast = 0;
  }

  readonly testValue = new FormControl();

  keyStepContacts = [
    [(100 / 10) * 1, 0],
    [(100 / 10) * 1, 25000],
    [(100 / 10) * 2, 37500],
    [(100 / 10) * 3, 50000],
    [(100 / 10) * 4, 62500],
    [(100 / 10) * 5, 75000],
    [(100 / 10) * 6, 87500],
    [(100 / 10) * 7, 100000],
    [(100 / 10) * 8, 112500],
    [(100 / 10) * 9, 125000],
    [(100 / 10) * 10, 130000],
  ];

  keyStepsDb = [
    [(100 / 7) * 1, 18000],
    [(100 / 7) * 2, 20000],
    [(100 / 7) * 3, 22000],
    [(100 / 7) * 4, 24000],
    [(100 / 7) * 5, 26000],
    [(100 / 7) * 6, 28000],
  ];

  keyStepsMessage = [
    [(100 / 9) * 1, 42000],
    [(100 / 9) * 2, 63000],
    [(100 / 9) * 3, 76000],
    [(100 / 9) * 4, 95000],
    [(100 / 9) * 5, 102000],
    [(100 / 9) * 6, 119000],
    [(100 / 9) * 7, 136000],
    [(100 / 9) * 8, 153000],
  ];

  goForm() {
    if (this.document.documentElement.scrollHeight <= 3715) {
      window.scrollTo({
        top: 2800,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 4100,
        behavior: 'smooth',
      });
    }
  }

  onlySimilarProducts() {}

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'black-theme');
    this.renderer.addClass(document.body, 'price-theme');
    of(this.contactsPrice).subscribe();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'black-theme');
    this.renderer.removeClass(document.body, 'price-theme');
    this.meta.removeTag("name='description'");
  }
}
