<div class="main-grid">
    <div class="title-box">
        <h1 class="title">Миссия</h1>
    </div>

    <p class="left-text opasity-ani">Почему с&nbsp;нами?</p>

    <div class="our-mission opasity-ani">
        <p class="text-title">
          Наша миссия&nbsp;&mdash; навести порядок в&nbsp;коммуникациях компаний с&nbsp;пользователями.
        </p>

        <p class="text">
          На&nbsp;рынке есть множество платформ, которые призваны решать разные коммуникационные задачи. Большие и&nbsp;маленькие, дорогие и&nbsp;бюджетные.
        </p>

        <p class="text">
          Одни отправляют емейлы, другие показывают всплывающие окна, третьи отправляют пуши и&nbsp;смски. Но&nbsp;не&nbsp;было решения, которое могло&nbsp;бы покрыть все ваши запросы в&nbsp;одном приложении, в&nbsp;одном окне.
        </p>

        <p class="text text--no-mb">
          Теперь есть.
        </p>
    </div>

    <p class="left-text opasity-ani">Знакомьтесь&nbsp;&mdash; enKod:</p>

    <p class="list-left text text--no-mb opasity-ani">Всплывающие окна (Pop-Up)</p>

    <p class="list-right text text--no-mb opasity-ani">Любые виды товарных рекомендаций</p>

    <p class="list-left text text--no-mb opasity-ani">Неограниченная отправка PUSH сообщений</p>

    <p class="list-right text text--no-mb opasity-ani">Работа с&nbsp;Big Data</p>

    <p class="list-left text text--no-mb opasity-ani">Отправка Email, SMS и&nbsp;сообщений в&nbsp;мессенджеры</p>

    <p class="list-right list-right--no-mb text text--no-mb opasity-ani">Персональный эксперт, который научит как с&nbsp;этим работать и&nbsp;будет на&nbsp;связи всегда и&nbsp;по&nbsp;любому вопросу</p>

    <p class="list-left text text--no-mb opasity-ani">Отслеживание и&nbsp;хранение действий пользователей на&nbsp;сайте</p>

    <p class="end-text opasity-ani">Одно окно. <br class="br-hided">Одно приложение. <br class="br-hided">Одно решение для всех задач.</p>
</div>
