<div class="main-grid">
  <div class="title-box">
      <h1 class="title">Демонстрация</h1>
  </div>

  <p class="demo-sub-title opasity-ani">Хотите увидеть систему своими глазами?</p>

  <p class="demo-text opasity-ani">Лучший способ понять подходит&nbsp;ли сервис enKod под ваши задачи&nbsp;&mdash; это посмотреть его изнутри и&nbsp;пообщаться с&nbsp;нашим менеджером. Мы&nbsp;дадим вам месячный тестовый период на&nbsp;знакомство с&nbsp;enKod.</p>

  <p class="demo-sub-title demo-sub-title--mb opasity-ani">Проведем демо enKod в&nbsp;наших лучших традициях:</p>

  <p class="demo-item opasity-ani">в&nbsp;удобное для вас время</p>

  <p class="demo-item opasity-ani opasity-ani-2">подробно и&nbsp;человеческим языком</p>

  <p class="demo-item opasity-ani opasity-ani-3">учтем ваши задачи и&nbsp;сферу бизнеса</p>

  <p class="demo-item opasity-ani opasity-ani-4">ответим на&nbsp;все вопросы</p>

</div>
